require( './src/styles/app.css' );

export const onRouteUpdate = ({ location }) => {
  // Check if we're not on the "/peo" route
  if (!location.pathname.startsWith("/peo")) {
    if (!document.querySelector('link[href="https://kinetic-styles.netlify.app/Styles.css"]')) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "https://kinetic-styles.netlify.app/Styles.css";
      link.type = "text/css";
      document.head.appendChild(link);
    }
  }
};



export const onInitialClientRender = () => {
  setTimeout(() => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.classList.add('hidden');
      setTimeout(() => {
        preloader.style.display = 'none';
      }, 300);
    }
  }, 1000);
};
